import React, { useContext, useState } from "react";

//Context
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import YearPopover from "../popovers/year";

import { TimeContext } from "../../config/time";

//Jquery

export default function ChartCard(props) {
  const setTimeConfig = useContext(TimeContext).setTimeConfig;
  const timeConfig = useContext(TimeContext).timeConfig;
  const { title, showYearSelector } = props;

  const handleSubmit = (year) => () => {
    setTimeConfig(year);
    setAnchorFilter(null);
  };

  const [anchorFilter, setAnchorFilter] = useState(null);
  const handleClick = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorFilter(null);
  };

  return (
    <Card>
      <YearPopover
        open={anchorFilter}
        handleSubmit={handleSubmit}
        handleClose={handlePopoverClose}
      />
      <CardHeader
        title={<Typography variant="h5">{title}</Typography>}
        action={
          showYearSelector ? (
            <Chip color="info" label={timeConfig} onClick={handleClick} />
          ) : null
        }
      />
      <CardContent> {props.children} </CardContent>
    </Card>
  );
}
