import { createContext } from "react";

export const timeConfigs = [
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
];

export const TimeContext = createContext({
  timeConfig: timeConfigs[0],
  changeTimeConfig: () => {},
});
