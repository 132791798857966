import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#A7AE9C",
    },
    success: {
      main: "#28A44D",
    },
    error: {
      main: "#C33149",
    },
    warning: {
      main: "#ED7D31",
    },
  },
});

export default theme;

/*
Gradient
 #FD0329
 #E4006C
 #AC3793
 #684D98
 #33507F
 #2F4858
 */
