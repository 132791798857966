import * as React from "react";

//Context
import { timeConfigs, TimeContext } from "../../config/time";

//React router dom
import { Route, Routes } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";

import Box from "@mui/material/Box";

//Selfmade components
import BottomNavbar from "../../components/navigation/bottomNavbar";
import Navbar from "../../components/navigation/navbar";
import CategoryDashboard from "./categoryDashboard";
import Dashboard from "./dashboardV2";

export default function Home(props) {
  const lastTimeConfig = localStorage.getItem("timeConfig") ?? timeConfigs[0];
  const [timeConfig, setTimeConfig] = React.useState(lastTimeConfig);
  return (
    <TimeContext.Provider value={{ timeConfig, setTimeConfig }}>
      <Box sx={{ display: "flex", bgcolor: "#C1CCD2" }}>
        <CssBaseline />
        <Navbar />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Toolbar />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/categorias" element={<CategoryDashboard />} />
          </Routes>
        </Box>
        <BottomNavbar />
      </Box>
    </TimeContext.Provider>
  );
}
