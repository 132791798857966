import React, { useState, useEffect, useContext } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { TreeView } from "@mui/x-tree-view/TreeView";

import $ from "jquery";
import apiURL from "../../config/environment";
import { TimeContext } from "../../config/time";

import ExpandMoreIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandMore";

import CustomizedTreeItem from "../kpis/treeView";

export default function MainTreeview(props) {
  const [searchParams] = useSearchParams();

  const [data, setData] = useState([]);

  const timeConfig = useContext(TimeContext).timeConfig;
  const navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = true;

    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    const params = [];

    searchParams.forEach((value, key) => {
      params.push(value);
    });

    const catParam = params.length > 0 ? params[0] : "";

    $.ajax({
      method: "GET",
      url: `${apiURL}dashboard/sales-discrimination?year=${timeConfig}&categories=${catParam}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setData(res);
        }
      })
      .fail((res) => {
        if (res.status === 401) {
          alert("La sesión ha expirado");
          navigate("/login");
        }
      });
    return () => (isSubscribed = false);
  }, [timeConfig, searchParams, navigate]);

  return (
    <TreeView
      aria-label="gmail"
      defaultExpanded={["3"]}
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
      sx={{ height: 300, flexGrow: 1, overflowY: "auto" }}
    >
      {data.map((cat) => (
        <CustomizedTreeItem item={cat} />
      ))}
    </TreeView>
  );
}
