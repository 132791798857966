import React, { useState, useEffect, useContext, useRef } from "react";

//Context
import { TimeContext } from "../../config/time";

import { useSearchParams } from "react-router-dom";

//Charts
import ReactEcharts from "echarts-for-react";

//Jquery
import apiURL from "../../config/environment";
import $ from "jquery";

export default function DistributionChart(props) {
  const [chartSettings, setChartSettings] = useState({});
  const timeConfig = useContext(TimeContext).timeConfig;

  const [searchParams] = useSearchParams();
  const { type } = props;

  useEffect(() => {
    let isSubscribed = true;
    const params = [];

    searchParams.forEach((value, key) => {
      params.push(value);
    });

    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;

    const catParam = params.length > 0 ? params[0] : "";

    $.ajax({
      method: "GET",
      url: `${apiURL}dashboard/${type}-distribution?categories=${catParam}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      if (isSubscribed) {
        const data = res;

        setChartSettings({
          color: ["#979797", "#404470", "#1e76ac", "#cdcd35"],
          // Global text styles
          textStyle: {
            fontFamily: "Roboto, Arial, Verdana, sans-serif",
            fontSize: 13,
          },

          title: {
            subtext: "En el stock disponible",
            left: "center",
          },

          // Add tooltip
          tooltip: {
            trigger: "item",
            //backgroundColor: "rgba(0,0,0,0.75)",
            padding: [10, 15],
            textStyle: {
              fontSize: 13,
              fontFamily: "Roboto, sans-serif",
            },
            formatter: `{b}: {c}`,
          },

          // Add legend

          // Add series
          series: [
            {
              // name: "Increase (brutto)",
              type: "pie",
              radius: ["10%", "50%"],
              center: ["50%", "50%"],
              roseType: "radius",
              itemStyle: {
                borderWidth: 1,
                borderColor: "#fff",
              },
              data: data,
            },
          ],
        });
      }
    });

    return () => (isSubscribed = false);
  }, [timeConfig, searchParams, type]);

  return <ReactEcharts ref={useRef("echarts_react")} option={chartSettings} />;
}
