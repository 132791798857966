import React, { useEffect, useState } from "react";

import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Card from "@mui/material/Card";

import apiURL from "../../config/environment";
import $ from "jquery";
//import { TimeContext } from "../../config/time";

export default function OldestSalesCard(props) {
  //const { Icon, text, handleClick } = props;
  const [values, setValues] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    $.ajax({
      method: "GET",
      url: `${apiURL}dashboard/oldest-sales`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      if (isSubscribed) {
        setValues(res);
      }
    });
    return () => (isSubscribed = false);
  }, []);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={
          <Typography variant="h5">
            {"Líneas con ventas más antiguas"}
          </Typography>
        }
      />
      <CardContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{"Línea"}</TableCell>
              <TableCell>{"Ultima venta"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((val, key) => (
              <TableRow key={key}>
                <TableCell>{val.category}</TableCell>
                <TableCell>
                  {new Date(val.lastSale).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

/*  {/* <Card>
 */
