import React from "react";

import HomeRouter from "../views/private/home";
import { Navigate } from "react-router-dom";

export default function ValidateSession(props) {
  const isAuthenticated = Boolean(localStorage.getItem("userInfo"));

  if (isAuthenticated) return <HomeRouter />;
  else return <Navigate to="/login" />;
}
