import React from "react";
import Autocomplete from "@mui/material/Autocomplete";

import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

/* const useCategories = () => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    let isSubscribed = true;
    $.ajax({
      method: "GET",
      url: `${mainURL}dropdown/category`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      //console.log(res);
      //const aux = res.map((m) => m.name);
      if (isSubscribed) setCategories(res);
    });
    return () => (isSubscribed = false);
  }, []);
  return categories;
}; */

export default function SelectMultipleCategories(props) {
  //const [selected, setSelected] = useState([]);

  const { options, values, handleChange, handleValidate } = props;

  return (
    <Grid
      container
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid item xs={10}>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            id="tags-standard"
            options={options}
            value={values}
            getOptionLabel={(opt) => opt.name}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Líneas" />
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={2}
        container
        alignItems={"center"}
        justifyContent={"center"}
      >
        <IconButton onClick={handleValidate}>
          <CheckCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
