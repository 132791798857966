import React, { useState } from "react";

//MUI
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

//Selfmade
import Copyright from "../../components/copyright";

//React router dom
import { useNavigate } from "react-router-dom";

import apiURL from "../../config/environment";
import $ from "jquery";

export default function Login() {
  const navigate = useNavigate();

  //Load management
  const [isLoading, setLoading] = useState(false);

  //Data management
  const [model, setModel] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setModel({
      ...model,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    $.ajax({
      method: "POST",
      url: apiURL + "login/authenticate-user",
      contentType: "application/json",
      data: JSON.stringify(model),
    })
      .done((res) => {
        setLoading(false);
        localStorage.setItem("userInfo", JSON.stringify(res));
        navigate("/");
      })
      .fail((res) => {
        setLoading(false);
        if (res.status === 401) {
          alert("Email or password incorrect");
        }
      });
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          //backgroundColor: "red",
        }}
      >
        <img
          src="assets/images/isotipo.png"
          alt="Calzado la gran rebaja logo"
          width="80%"
        />
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 5, width: "100%" }}
        >
          <TextField
            margin="dense"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
          />

          {isLoading ? (
            <Grid container justifyContent={"center"} sx={{ mt: 3, mb: 2 }}>
              <CircularProgress />
            </Grid>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar sesión
            </Button>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Box>
    </Container>
  );
}
