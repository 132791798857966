import React, { useState, useEffect, useContext, useRef } from "react";

//Context
import { TimeContext } from "../../config/time";

//Charts
import ReactEcharts from "echarts-for-react";

import { useNavigate, useSearchParams } from "react-router-dom";

//Jquery
import apiURL from "../../config/environment";
import $ from "jquery";

export default function SalesOverTimeChart(props) {
  const [chartSettings, setChartSettings] = useState({});
  const timeConfig = useContext(TimeContext).timeConfig;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = true;

    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    const params = [];

    searchParams.forEach((value, key) => {
      params.push(value);
    });

    const catParam = params.length > 0 ? params[0] : "";

    $.ajax({
      method: "GET",
      url: `${apiURL}dashboard/sales-over-time?year=${timeConfig}&categories=${catParam}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        //console.log(res);
        let values = [];
        const categories = [...new Set(res.map((item) => item.category))];
        const densities = [...new Set(res.map((item) => item.density))];
        const combination = [
          ...new Set(res.map((item) => `${item.density} ${item.category}`)),
        ];

        const months = [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ];

        categories.forEach((cat) => {
          densities.forEach((dens) => {
            const data = [];
            months.forEach((month) => {
              data.push(
                res.find(
                  (x) =>
                    x.xAxis.trim() === month &&
                    x.category === cat &&
                    x.density === dens
                )?.yAxis
              );
            });

            const obj = {
              name: `${dens} ${cat}`,
              type: dens.includes("Total") ? "bar" : "line",
              color: dens.includes("Total")
                ? cat === categories[0]
                  ? "#1e81b0"
                  : "#82d3e5"
                : cat === categories[0]
                ? "#e28743"
                : "#eab676",

              smooth: false,
              symbolSize: 6,
              //symbol: "circle",
              lineStyle: {
                borderWidth: 2,
                type: cat === "2021" ? "dotted" : "solid",
                join: "miter",
                //color: "#47995b",
              },
              itemStyle: {
                borderWidth: 0,
              },
              barWidth: cat === cat[0] ? "20%" : "35%",
              barGap: "0",
              data: data,
              yAxisIndex: dens === densities[0] ? 0 : 1,
            };

            values.push(obj);
          });
        });

        if (isSubscribed) {
          setChartSettings({
            //color: colors,

            // Global text styles
            textStyle: {
              fontFamily: "Roboto, Arial, Verdana, sans-serif",
              fontSize: 13,
            },

            // Cha23rt animation duration
            animationDuration: 750,

            // Setup grid
            grid: {
              left: 30,
              right: 30,
              top: 50,
              bottom: 10,
              containLabel: true,
            },

            // Add legend
            legend: {
              data: combination,
              width: 300,
              height: 200,
              itemHeight: 5,
              itemGap: 5,
              top: "top",
              //padding: 10,
            },

            // Add tooltip
            tooltip: {
              trigger: "axis",
              padding: [10, 15],
              textStyle: {
                fontSize: 13,
                fontFamily: "Roboto, sans-serif",
              },
            },

            // Horizontal axis
            xAxis: [
              {
                type: "category",
                boundaryGap: true,
                axisLabel: {
                  color: "#333",
                },
                axisLine: {
                  lineStyle: {
                    color: "#999",
                  },
                }, //Fechas
                data: months,
              },
            ],

            // Vertical axis
            yAxis: [
              {
                type: "value",
                name: densities[0],
                nameLocation: "middle",
                max: 10000,
                //interval: endpoint.includes("detector") ? 700 : undefined,
                nameGap: 50,
                axisLabel: {
                  formatter: "{value} ",
                  color: "#333",
                },
              },
              {
                type: "value",
                name: densities[1],
                nameLocation: "middle",
                nameGap: 50,
                axisLabel: {
                  formatter: "{value}",
                  color: "#333",
                },
              },
            ],

            // Add series
            series: values,
          });
        }
      })
      .fail((res) => {
        if (res.status === 401) {
          alert("La sesión ha expirado");
          navigate("/login");
        }
      });
    return () => (isSubscribed = false);
  }, [timeConfig, searchParams, navigate]);

  return <ReactEcharts ref={useRef("echarts_react")} option={chartSettings} />;
}
