import { Link, Typography } from "@mui/material";
import React from "react";

export default function Copyright(props) {
  return (
    <Link color="inherit" href="https://preztel.com/">
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {`Copyright © Preztel SAS ${new Date().getFullYear()}.`}
      </Typography>
    </Link>
  );
}
