import React, { useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { useSearchParams } from "react-router-dom";

import apiURL from "../../config/environment";
import $ from "jquery";

const emptyValues = {
  lastMonthQuantity: "",
  lastMonthInterval: "",
  date: "",
  quantity: "",
  color: "",
  size: "",
  manufacturer: "",
};

export default function InfoCard(props) {
  //const { Icon, text, handleClick } = props;

  const [values, setValues] = useState(emptyValues);
  const [isLoading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let isSubscribed = true;
    const params = [];
    setLoading(true);

    searchParams.forEach((value, key) => {
      params.push(value);
    });

    const catParam = params.length > 0 ? params[0] : "";
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    $.ajax({
      method: "GET",
      url: `${apiURL}dashboard/last-sale-info?categories=${catParam}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        if (isSubscribed) {
          setLoading(false);
          setValues(res);
        }
      })
      .fail((res) => {
        setValues(emptyValues);
      });
    return () => (isSubscribed = false);
  }, [searchParams]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={
          <Typography variant="h5">
            {"Información de las líneas seleccionadas"}
          </Typography>
        }
        action={isLoading ? <CircularProgress /> : null}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={1} ml={1}>
            <Grid item xs={12} ml={-1}>
              <Typography variant="h6">
                {`Información del último mes`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{"Periodo"}</Typography>
              <Typography variant="body2" color={"textSecondary"}>
                {values.lastMonthInterval}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{"Cantidad vendida"}</Typography>
              <Typography variant="body2" color={"textSecondary"}>
                {values.lastMonthQuantity}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1} ml={1}>
            <Grid item xs={12} ml={-1}>
              <Typography variant="h6">
                {`Información de la última venta`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{"Fecha"}</Typography>
              <Typography variant="body2" color={"textSecondary"}>
                {values.date?.split(" ")[0]}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{"Cantidad"}</Typography>
              <Typography variant="body2" color={"textSecondary"}>
                {values.quantity}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{"Color"}</Typography>
              <Typography variant="body2" color={"textSecondary"}>
                {values.color}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{"Talla"}</Typography>
              <Typography variant="body2" color={"textSecondary"}>
                {values.size}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
