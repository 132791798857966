import React, { useEffect, useState } from "react";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//MUI-LAB
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";

import apiURL from "../../config/environment";
import $ from "jquery";
import { useSearchParams } from "react-router-dom";

export default function StockCard(props) {
  //const { Icon, text, handleClick } = props;
  const [value, setValue] = useState(0);
  const [searchParams] = useSearchParams();

  const numberWithComas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const [untilDate, setUntilDate] = useState(Date.now());
  const handleDateChange = (event) => {
    const date = event;
    setUntilDate(date);
  };

  useEffect(() => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push(value);
    });

    const catParam = params.length > 0 ? params[0] : "";

    const dateParam = isNaN(new Date(untilDate))
      ? ""
      : new Date(untilDate).toISOString().split("T")[0];

    let isSubscribed = true;

    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    $.ajax({
      method: "GET",
      url: `${apiURL}dashboard/stock-available?categories=${catParam}&date=${dateParam}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      if (isSubscribed) {
        setValue(res.stockAvailable);
      }
    });
    return () => (isSubscribed = false);
  }, [searchParams, untilDate]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent sx={{ height: "100%" }}>
        <Grid container alignItems={"center"} height={"100%"}>
          <Grid item xs={6}>
            <SelectDateComponent
              value={untilDate}
              handleChange={handleDateChange}
            />
          </Grid>
          <Grid item container xs={6}>
            <Grid item container justifyContent={"flex-end"}>
              <Typography variant="body1">{"Stock disponible"}</Typography>
            </Grid>
            <Grid container justifyContent={"flex-end"}>
              <Typography variant="h3">{numberWithComas(value)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function SelectDateComponent(props) {
  const { value, handleChange } = props;

  return (
    <Grid item container justifyContent={"flex-start"} alignItems={"center"}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={"Hasta la fecha"}
          value={value}
          onChange={handleChange}
          format="dd/MM/yyyy"
        />
      </LocalizationProvider>
    </Grid>
  );
}
