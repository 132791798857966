import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StraightenIcon from "@mui/icons-material/Straighten";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import Category from "@mui/icons-material/Category";

import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
  const theme = useTheme();
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    ...other
  } = props;

  const styleProps = {
    "--tree-view-color":
      theme.palette.mode !== "dark" ? color : colorForDarkMode,
    "--tree-view-bg-color":
      theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
  };

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 0.5,
            pr: 0,
          }}
        >
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={styleProps}
      {...other}
      ref={ref}
    />
  );
});

export default function CustomizedTreeItem(props) {
  const { item } = props;
  return (
    <StyledTreeItem
      nodeId={item.category}
      labelText={item.category}
      labelIcon={Category}
      labelInfo={item.quantity}
    >
      {item.months.map((mon) => (
        <StyledTreeItem
          nodeId={mon.month}
          labelText={mon.month}
          labelInfo={mon.quantity}
          labelIcon={CalendarMonthIcon}
        >
          {mon.sizes.map((size) => (
            <StyledTreeItem
              nodeId={size.size}
              labelText={size.size}
              labelInfo={size.quantity}
              labelIcon={StraightenIcon}
            >
              {size.colors.map((color) => (
                <StyledTreeItem
                  nodeId={color.color}
                  labelText={color.color}
                  labelInfo={color.quantity}
                  labelIcon={ColorLensIcon}
                />
              ))}
            </StyledTreeItem>
          ))}
        </StyledTreeItem>
      ))}
    </StyledTreeItem>
  );
}
