import { Route, Routes } from "react-router-dom";

import Login from "./views/public/login";
import ValidateSession from "./middlewares/validateSession";

import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<ValidateSession />} />
    </Routes>
  );
}

export default App;
