import React from "react";

//MUI
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";

export default function YearPopover(props) {
  const { open, handleSubmit, handleClose } = props;

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleClose}
    >
      <List dense>
        {["2018", "2019", "2020", "2021", "2022", "2023", "2024"].map(
          (year) => (
            <ListItemButton key={year} onClick={handleSubmit(year)}>
              <ListItemText primary={year} />
            </ListItemButton>
          )
        )}
      </List>
    </Popover>
  );
}
