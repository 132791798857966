import React, { useState } from "react";

import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";

//Selfmade
import ProfilePopover from "../popovers/profile";

export default function Navbar(props) {
  const navbarName = JSON.parse(localStorage.getItem("userInfo")).fullName;
  //Profile popover management
  const [anchorProfile, setAnchorProfile] = useState(null);
  const handleProfilePopoverOpen = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleProfilePopoverClose = () => {
    setAnchorProfile(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <ProfilePopover
        open={anchorProfile}
        handleClose={handleProfilePopoverClose}
      />
      <Toolbar>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={3} sm={6} container alignItems={"center"}>
            <img
              src="/assets/images/isotipo-white.png"
              alt="Bellevue white logo"
              height={"45px"}
            />
          </Grid>
          <Grid
            item
            container
            xs={9}
            sm={6}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <ButtonBase color="inherit" onClick={handleProfilePopoverOpen}>
              <Typography variant="subtitle2">{navbarName}</Typography>
            </ButtonBase>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
