import React, { useState, useEffect } from "react";

import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BottomNavigation from "@mui/material/BottomNavigation";
import Paper from "@mui/material/Paper";

//Icons

import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";

import { useNavigate } from "react-router-dom";

export default function BottomNavbar(props) {
  const currentPath = window.location.pathname;
  const [value, setValue] = useState(currentPath);
  const navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = true;
    const mainRoute = `/${currentPath.split("/")[1]}`;
    if (isSubscribed) setValue(mainRoute);
    return () => (isSubscribed = false);
  }, [currentPath]);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <BottomNavigation
        showLabels
        //sx={{ display: { xs: "none", md: "flex" } }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(newValue);
        }}
      >
        <BottomNavigationAction
          value={"/"}
          label="Inicio"
          icon={<DashboardIcon />}
        />
        <BottomNavigationAction
          value={"/categorias"}
          label="Análisis por línea"
          icon={<CategoryIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}
