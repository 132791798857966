import React, { useContext, useEffect, useState } from "react";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RemoveIcon from "@mui/icons-material/Remove";

import apiURL from "../../config/environment";
import $ from "jquery";
import { TimeContext } from "../../config/time";
import { Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const emptyValue = {
  currentValue: 0,
  currentPeriod: "",
  previousValue: 0,
  previousPeriod: "",
  variation: 0,
};

export default function SalesCard(props) {
  const [value, setValue] = useState(emptyValue);
  const timeConfig = useContext(TimeContext).timeConfig;
  const [searchParams] = useSearchParams();

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const numberAbreviation = (number) => {
    if (number > 1000000) {
      return `${(number / 1000000).toFixed(0)}M`;
    } else if (number > 1000) {
      return `${(number / 1000).toFixed(0)}K`;
    } else {
      return number.toString();
    }
  };

  useEffect(() => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push(value);
    });

    const catParam = params.length > 0 ? params[0] : "";

    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    $.ajax({
      method: "GET",
      url: `${apiURL}dashboard/sales?year=${timeConfig}&categories=${catParam}`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      if (isSubscribed) {
        setValue(res);
      }
    });
    return () => (isSubscribed = false);
  }, [timeConfig, searchParams]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <AttachMoneyIcon fontSize={"large"} />
            <Typography variant="body1">{"Ventas"}</Typography>
          </Grid>
          <Grid item container xs={8}>
            <VariationComponent value={value} />
            <Grid container justifyContent={"flex-end"}>
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography variant="body2" textAlign={"center"}>
                      {`${numberWithCommas(value.currentValue)}`}
                    </Typography>
                    <Typography variant="body2">
                      {value.currentPeriod}
                    </Typography>
                  </React.Fragment>
                }
              >
                <Typography variant="h3">{`$ ${numberAbreviation(
                  value.currentValue
                )}`}</Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function VariationComponent(props) {
  const { value } = props;
  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <Grid item container justifyContent={"flex-end"}>
      {value.variation === 0 ? (
        <RemoveIcon color="info" />
      ) : value.variation > 0 ? (
        <TrendingUpIcon color="success" />
      ) : (
        <TrendingDownIcon color="error" />
      )}
      <Tooltip
        title={
          <React.Fragment>
            <Typography variant="body2" textAlign={"center"}>
              {`${numberWithCommas(value.previousValue)}`}
            </Typography>
            <Typography variant="body2">{value.previousPeriod}</Typography>
          </React.Fragment>
        }
      >
        <Typography
          variant="subtitle1"
          color={value.variation > 0 ? "green" : "error"}
        >{`${value.variation}%`}</Typography>
      </Tooltip>
    </Grid>
  );
}
