import React, { useState, useEffect } from "react";

import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SelectMultipleCategories from "../../components/input/selectCategory";
import DistributionChart from "../../components/charts/distribution";
import SalesOverTimeChart from "../../components/charts/salesOverTime";
import ChartCard from "../../components/charts/chartCard";
import StockCard from "../../components/kpis/stock";
import SalesCard from "../../components/kpis/sales";

import { useSearchParams } from "react-router-dom";

import $ from "jquery";
import mainURL from "../../config/environment";
import InfoCard from "../../components/kpis/info";
import MainTreeview from "../../components/collapse/main";

const useCategories = () => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    let isSubscribed = true;
    $.ajax({
      method: "GET",
      url: `${mainURL}dropdown/category`,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      if (isSubscribed) setCategories(res);
    });
    return () => (isSubscribed = false);
  }, []);
  return categories;
};

export default function CategoryDashboard(props) {
  const availableCategories = useCategories();
  const [categories, setCategories] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (newValue) => {
    if (newValue.length === 0) setSearchParams(new URLSearchParams());
    setCategories([...newValue]);
  };

  const handleValidate = (event) => {
    const ids = categories.map((m) => m.id).join(",");
    const newParams = new URLSearchParams();
    newParams.set("categories", ids);
    setSearchParams(newParams);
  };

  useEffect(() => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push(value);
    });

    const ids = params.length > 0 ? params[0]?.split(",") : [];
    let isSubscribed = true;

    if (isSubscribed) {
      const selected = availableCategories.filter((item) =>
        ids.includes(item.id.toString())
      );
      setCategories(selected);
    }

    return () => (isSubscribed = false);
  }, [searchParams, availableCategories]);

  return (
    <Grid container spacing={2} sx={{ p: 3, pb: 10, bgcolor: "#C1CCD2" }}>
      {/* <Grid item xs={12} container spacing={1}> */}
      <Grid item xs={12} md={12} lg={6}>
        <Card sx={{ height: "100%" }}>
          <CardContent sx={{ height: "100%" }}>
            <SelectMultipleCategories
              handleValidate={handleValidate}
              options={availableCategories}
              handleChange={handleChange}
              values={categories}
              x
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <StockCard />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <SalesCard />
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <ChartCard title="Historial de ventas" showYearSelector>
          <SalesOverTimeChart />
        </ChartCard>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ChartCard title="Discriminación de ventas" showYearSelector>
          <MainTreeview />
        </ChartCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InfoCard />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ChartCard title="Distribución de tallas">
          <DistributionChart type="size" />
        </ChartCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ChartCard title="Distribución de colores">
          <DistributionChart type="color" />
        </ChartCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ChartCard title="Distribución de proveedores">
          <DistributionChart type="manufacturer" />
        </ChartCard>
      </Grid>
    </Grid>
  );
}
