import React from "react";

import Grid from "@mui/material/Grid";
import ChartCard from "../../components/charts/chartCard";
import SalesOverTimeChart from "../../components/charts/salesOverTime";
import StockCard from "../../components/kpis/stock";
import SalesCard from "../../components/kpis/sales";
import OldestSalesCard from "../../components/kpis/oldestSales";
import DistributionChart from "../../components/charts/distribution";

export default function Dashboard(props) {
  return (
    <Grid container spacing={2} sx={{ p: 3, pb: 10 }}>
      <Grid item xs={12} md={6}>
        <StockCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <SalesCard />
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <ChartCard title="Historial de ventas">
          <SalesOverTimeChart />
        </ChartCard>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ChartCard title="Distribución de colores">
          <DistributionChart type="color" />
        </ChartCard>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ChartCard title="Distribución de proveedores">
          <DistributionChart type="manufacturer" />
        </ChartCard>
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <OldestSalesCard />
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
}
